<template functional>
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
   <path d="M26.25 11.8682V9.04539L29.8682 1.80906C30.0617 1.42148 30.0409 0.96125 29.8133 0.592656C29.5855 0.224609 29.1834 0 28.75 0H23.75C23.4186 0 23.1005 0.131875 22.8662 0.36625L21.25 1.98242L19.6338 0.36625C19.3995 0.131875 19.0815 0 18.75 0H11.25C10.8166 0 10.4145 0.224609 10.1868 0.592656C9.95914 0.961328 9.93836 1.42148 10.1319 1.80906L13.75 9.04539V11.8682C8.18937 15.9591 3.75 24.1945 3.75 30.5975C3.75 40 12.0111 40 20 40C27.9889 40 36.25 40 36.25 30.5975C36.25 24.1945 31.8106 15.9591 26.25 11.8682ZM16.25 10H23.75V11.25H16.25V10ZM18.2324 2.5L20.3662 4.63383C20.8545 5.12211 21.6455 5.12211 22.1338 4.63383L24.2676 2.5H26.7273L24.2273 7.5H15.7727L13.2727 2.5H18.2324Z"/>
  <path d="M13 26H19V20H13V26ZM15 22H17V24H15V22ZM21 20V26H27V20H21ZM25 24H23V22H25V24ZM13 34H19V28H13V34ZM15 30H17V32H15V30ZM26 28H27V32H24V31H23V34H21V28H24V29H26V28ZM26 33H27V34H26V33ZM24 33H25V34H24V33Z" fill="white"/>
</svg>
</template>

<script>
export default {
  name: 'IconBagSolid'
}
</script>

<style lang="scss" scoped>
</style>
