<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in writeOffs"
        :key="index")
        .list-1(@click="showDetailsModal(item)")
          .list-1__title {{ item.ingredient_name }}
          .list-1__item
            .list-1__item-label {{ 'pages.storage.amount_write_off' | translate }} [{{ 'units.kg' | translate }}]
            .list-1__item-value {{ item.weight }}
          .list-1__item
            .list-1__item-label {{ 'base.price' | translate }}
            .list-1__item-value {{ item.price }}
          .list-1__item
            .list-1__item-label {{ 'base.provider' | translate }}
            .list-1__item-value {{ item.provider_name }}
          .list-1__item
            .list-1__item-label {{ 'base.date' | translate }}
            .list-1__item-value {{ item.created_at | date }}

      v-table-body(
        v-if="!loading"
        outsideTable
        :empty="!writeOffs.length")

    template(v-else)
      v-card.mb-2(v-for="i in 5" :key="i")
        .list-1
          .list-1__title
            .list-1__title-skeleton
          .list-1__item(v-for="j in 4" :key="`${i}-${j}`")
            .list-1__item-skeleton

    template(
      v-if="!loading && showPagination")
      v-paginate(
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler")

    v-dialog(
      max-width="380"
      v-model="modal.showDetailsModal")
      details-modal(
        :writeOff="currentItem"
        @close="modal.showDetailsModal = false")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import paginationMixin from '@/mixins/pagination.mixin'
import DetailsModal from './modals/DetailsModal'

export default {
  name: 'ExpenseTableMobile',

  components: {
    DetailsModal
  },

  mixins: [paginationMixin],

  data: () => ({
    currentItem: null,
    modal: {
      showDetailsModal: false
    }
  }),

  mounted () {
    this.setupPagination(this.fetchWriteOffs)
  },

  methods: {
    ...mapActions(['fetchWriteOffs']),

    showDetailsModal (item) {
        this.currentItem = item
        this.modal.showDetailsModal = true
    }
  },

  computed: {
    ...mapGetters([
      'writeOffs'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
