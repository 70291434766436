import { render, staticRenderFns } from "./IconBagRegular.vue?vue&type=template&id=578843ea&scoped=true&functional=true"
import script from "./IconBagRegular.vue?vue&type=script&lang=js"
export * from "./IconBagRegular.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "578843ea",
  null
  
)

export default component.exports