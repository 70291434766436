<template functional>
  <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
   <path d="M26.25 11.8682V9.04539L29.8682 1.80906C30.0617 1.42148 30.0409 0.96125 29.8133 0.592656C29.5855 0.224609 29.1834 0 28.75 0H23.75C23.4186 0 23.1005 0.131875 22.8662 0.36625L21.25 1.98242L19.6338 0.36625C19.3995 0.131875 19.0815 0 18.75 0H11.25C10.8166 0 10.4145 0.224609 10.1868 0.592656C9.95914 0.961328 9.93836 1.42148 10.1319 1.80906L13.75 9.04539V11.8682C8.18937 15.9591 3.75 24.1945 3.75 30.5975C3.75 40 12.0111 40 20 40C27.9889 40 36.25 40 36.25 30.5975C36.25 24.1945 31.8106 15.9591 26.25 11.8682ZM16.25 10H23.75V11.25H16.25V10ZM18.2324 2.5L20.3662 4.63383C20.8545 5.12211 21.6455 5.12211 22.1338 4.63383L24.2676 2.5H26.7273L24.2273 7.5H15.7727L13.2727 2.5H18.2324ZM20 37.5C10.2295 37.5 6.25 36.8756 6.25 30.5975C6.25 25.5402 9.90969 17.6562 15.3912 13.75H24.6087C30.0903 17.6562 33.75 25.5402 33.75 30.5975C33.75 36.8756 29.7705 37.5 20 37.5Z"/>
  <path d="M13 25H19V19H13V25ZM15 21H17V23H15V21ZM21 19V25H27V19H21ZM25 23H23V21H25V23ZM13 33H19V27H13V33ZM15 29H17V31H15V29ZM26 27H27V31H24V30H23V33H21V27H24V28H26V27ZM26 32H27V33H26V32ZM24 32H25V33H24V32Z"/>
</svg>
</template>

<script>
export default {
  name: 'IconBagRegular'
}
</script>

<style lang="scss" scoped>
</style>
