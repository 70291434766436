<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.expense.detail.title' | translate }}
    v-card-content
      h3.card-subtitle.card-subtitle--size-md.mb-2 {{ 'base.name_ingredient' | translate }}
        p.card-text.card-text--size-sm.card-text--color-black.mt-1 {{ writeOff.ingredient_name }}
      h3.card-subtitle.card-subtitle--size-md.mb-2 {{ 'base.weight_quantity' | translate }}
        p.card-text.card-text--size-sm.card-text--color-black.mt-1 {{ writeOff.ingredient_id }}
      h3.card-subtitle.card-subtitle--size-md {{ 'base.bags_details' | translate }}
        .cols-l
          p.mt-2.mr-1(
            v-for="item in writeOff.ingredient_id"
            :key="item")
            v-dropdown(
              width-auto)
              v-btn(
                icon
                fill
                light-green)
                icon-bag-solid
              v-card(
                elevated
                slot="content")
                .qr-code
                  img(src="@/assets/img/qr-code_big.png" alt="qr-code")

    v-card-actions
      v-btn(
        @click="onClose") {{ 'actions.close' | translate }}
</template>

<script>
import IconBagRegular from '@/components/icons/IconBagRegular'
import IconBagSolid from '@/components/icons/IconBagSolid'

export default {
  name: 'DetailsModal',

  components: {
    IconBagRegular,
    IconBagSolid
  },

  props: {
    writeOff: {
      type: Object,
      required: true
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
